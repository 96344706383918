.loading-view {
    display: flex;
    flex: 1;
    height: 100vh;
    align-items: center;
    flex-flow: column wrap;
    justify-content: center;

    .app-logo {
        max-width: 75%;
        max-height: 20em;
    }

    .loading-text {
        margin-top: 1.3em;
        color: #fff;
        font-size: 1.5em;

        svg {
            margin-right: 0.5em;
        }
    }
}